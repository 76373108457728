import React  from  'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import ToggleButton from 'react-bootstrap/ToggleButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Field from './../finalFormFields/Field';
import TextInput from  './../finalFormFields/TextInput';
import DateSelectField from "../finalFormFields/DateSelectField";

const SearchForm = ({ isGrowingSeason, initialValues, onSubmit, onStatusChanges }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const showingActive = searchParams.get('status') !== '2';
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={
        ({
           submitError,
           handleSubmit,
           form,
           submitting,
           pristine,
        }) => (
          <form className="w-100 search-form" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12 col-lg-8 col-xl-8">
                <div className="row mb-4">
                  <div className="col">
                    <Field
                      component={TextInput}
                      label="&ensp;"
                      placeholder={`${t('Search')}...`}
                      name="q"
                      type="text"
                      wrapperClass=""
                    />
                  </div>
                  {isGrowingSeason && (
                    <>
                      <div className="col">
                        <Field
                          component={DateSelectField}
                          label={t('From date')}
                          name="from_date"
                          type="date"
                          wrapperClass=""
                        />
                      </div>
                      <div className="col">
                        <Field
                          component={DateSelectField}
                          label={t('To date')}
                          name="to_date"
                          type="date"
                          wrapperClass=""
                        />
                      </div>
                    </>
                  )}

                  <div className="col-12 col-md-auto d-flex align-items-end justify-content-end justify-content-md-start" style={{ minHeight: '50px' }}>
                    <button
                      className="btn btn-outline-dark mb-1"
                      type="submit"
                      disabled={submitting}
                    >
                      {t('Search')}
                    </button>
                  </div>
                </div>
              </div>

              { isGrowingSeason && (
                <div className="col-12 col-lg-4 col-xl-4 d-flex align-items-center">
                  <ButtonGroup className="ms-md-auto">
                    <ToggleButton
                      id="is-active-radio"
                      type="radio"
                      variant={!showingActive ? 'default' : 'default'}
                      className={showingActive ? 'bg-primary text-white' : 'bg-white text-primary'}
                      name="status"
                      value={1}
                      checked={showingActive}
                      onClick={() => onStatusChanges(1)}
                    >
                      {t('Active')}
                    </ToggleButton>

                    <ToggleButton
                      id="is-active-radio"
                      type="radio"
                      variant={!showingActive ? 'warning' : 'default'}
                      className={!showingActive ? 'text-white bg-warning' : 'bg-white text-warning'}
                      name="status"
                      checked={!showingActive}
                      value={2}
                      onClick={() => onStatusChanges(2)}
                    >
                      {t('Completed')}
                    </ToggleButton>
                  </ButtonGroup>
                </div>
              )}
            </div>

            {submitError && <div className="error">{submitError}</div>}
          </form>
        )}
    />
  );
};

export default SearchForm;
