import toastr from 'toastr';

import axiosClient from '../utils/axios';
import { DEFAULT_PAGE_SIZE } from '../constants/const';
import { transformValues } from '../utils/utils';
import {
  GROWING_SEASONS_FETCHED,
  GROWING_SEASON_FETCHED,
} from "../actionTypes/actionTypes";

export const createGrowingSeasonDS = (values) =>  () => new Promise(async (resolve, reject) =>  {
  axiosClient
    .post(`/fl/${values.farmland}/se/`, transformValues(values))
    .then((data) => {
      toastr.success('Growing season created successfully!');
      resolve({ success: true, growingSeason: data.data });
    })
    .catch(function (err) {
      if (err?.response?.data) {
        resolve (err.response.data);
      }
    });
});

export const updateGrowingSeasonDS = (values) =>  () => new Promise(async (resolve, reject) =>  {
  axiosClient
    .put(`/fl/${values.farmland}/se/${values.season_id}/`, transformValues(values))
    .then(() => {
      toastr.success('Growing season updated successfully!');
      resolve({ success: true });
    })
    .catch(function (err) {
      if (err?.response?.data) {
        resolve (err.response.data);
      }
    });
});

export const deleteGrowingSeasonDS = (seasonId) =>  () => new Promise(async (resolve) =>
  axiosClient
    .delete(`/se/${seasonId}/`)
    .then(() => {
      toastr.success('Growing season deleted successfully!');
      resolve({ success: true });
    })
    .catch(function (err) {
      if (err?.response?.data) {
        resolve (err.response.data);
      }
    }));

export const fetchGrowingSeasonsDS = (
  page = 1,
  pageSize = DEFAULT_PAGE_SIZE,
  filters = {},
) => async (dispatch) => {
  console.log({ filters });
  let filtersQS = '';
  if (filters?.q) {
    filtersQS += `&contains=${filters.q}`;
  }
  if (filters?.from_date) {
    filtersQS += `&from_date=${filters.from_date}`;
  }
  if (filters?.to_date) {
    filtersQS += `&to_date=${filters.to_date}`;
  }

  filtersQS += `&status=${filters.status}`;
  await axiosClient
    .get(`/se/all/?page=${page}&page_size=${pageSize}${filtersQS}`)
    .then(({ data }) => {
      dispatch({
        type: GROWING_SEASONS_FETCHED,
        payload: data,
      });
    })
    .catch(function (err) {
      return 'error';
    });
};

export const fetchGrowingSeasonDS = (id) => async (dispatch) => {
  await axiosClient
    .get(`/se/${id}/`)
    .then(({ data }) => {
      //const newData = data;
      // newData.coordinates.coordinates[0] = data.coordinates.coordinates[0].map(c => { return [c[1], c[0]]});
      dispatch({
        type: GROWING_SEASON_FETCHED,
        payload:  data,
      });
    })
    .catch(function (err) {
      return 'error';
    });
};
