import React, { useEffect, useState } from  'react';
import { Form, Field } from 'react-final-form';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import axiosClient from '../../utils/axios';
import DateSelectField from  './../finalFormFields/DateSelectField';
import SelectInput from  './../finalFormFields/SelectInput';
import TextAreaInput from  './../finalFormFields/TextAreaInput';
import TextInput from  './../finalFormFields/TextInput';

const GrowingSeasonForm = ({
  isCreate = true,
  onSubmit,
  initialValues,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [farmlands, setFarmlands] = useState([]);
  const cropTypes = useSelector((state) => state?.globals?.cropTypes);
  const irrigationTypes = useSelector((state) => state?.globals?.irrigationTypes);
  const cultivationMethods = useSelector((state) => state?.globals?.cultivationMethods);

  useEffect(() => {
    fetchFarmlands();
  }, []);

  const fetchFarmlands = async () => {
    await axiosClient
      .get('/fl/?page_size=10000')
      .then(({ data }) => {
        setFarmlands(data.results);
      })
      .catch(function (err) {
        return 'error';
      });
  };



  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={
        ({
          submitError,
          handleSubmit,
          submitting,
          pristine,
          values
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="container">
                <div className="col-12 col-md-12 col-lg-8 col-xl-6">
                  <Field
                    wrapperClass='my-3'
                    type='date'
                    component={DateSelectField}
                    label={t('Start date')}
                    name="start_date"
                    required
                  />

                  <Field
                    wrapperClass=''
                    disabled={farmlands?.length === 0}
                    extraLink={(
                      <Link to="/farm-lands">
                        {t('Manage farmlands')}
                      </Link>
                    )}
                    component={SelectInput}
                    label={t('Field')}
                    name="farmland"
                    options={farmlands && farmlands.map(st => ({
                      name: st.name,
                      value: st.farmland_id,
                    }))}
                    required
                  />

                  {farmlands?.length === 0 && (
                    <div className="border border-warning mt-2 p-2 bg-light text-dark">
                      <i>{t('no farmlands message')}</i>
                      <Link className="ms-2" to="/farm-lands/add">{t('Click here to add one now')}</Link>
                    </div>
                  )}

                  {/*<Field*/}
                  {/*  wrapperClass='my-3'*/}
                  {/*  component={TextInput}*/}
                  {/*  label={t('Name / Area')}*/}
                  {/*  name="name"*/}
                  {/*  type="text"*/}
                  {/*  required*/}
                  {/*/>*/}

                  <Field
                    wrapperClass='my-3'
                    component={SelectInput}
                    label={t('Crop')}
                    name="croptype"
                    options={cropTypes.map(st => ({
                      name: st.name,
                      value: st.croptype_id,
                    }))}
                    required
                  />

                  {/*<Field*/}
                  {/*  component={TextInput}*/}
                  {/*  label="Seed Type"*/}
                  {/*  name="seedtype"*/}
                  {/*  type="text"*/}
                  {/*/>*/}

                  <Field
                    component={SelectInput}
                    label={t('Irrigation')}
                    name="irrigationtype"
                    options={irrigationTypes.map(st => ({
                      name: st.name,
                      value: st.irrigationtype_id,
                    }))}
                  />

                  <Field
                    component={SelectInput}
                    label={t('Cultivation Method')}
                    name="cultivationmethod"
                    options={cultivationMethods.map(st => ({
                      name: st.name,
                      value: st.cultivationmethod_id,
                    }))}
                  />


                  <Field
                    component={TextAreaInput}
                    label={t('Comments')}
                    name="comments"
                  />

                  {/*<Field*/}
                  {/*  component={TextAreaInput}*/}
                  {/*  label="Fertilizer"*/}
                  {/*  name="fertilizer"*/}
                  {/*/>*/}


                  {/*<Field*/}
                  {/*  component={TextAreaInput}*/}
                  {/*  label="Pesticide"*/}
                  {/*  name="pesticide"*/}
                  {/*/>*/}


                  {/*<Field*/}
                  {/*  type='date'*/}
                  {/*  component={DateSelectField}*/}
                  {/*  label="To Date"*/}
                  {/*  name="todate"*/}
                  {/*  required*/}
                  {/*/>*/}

                  {/*<Field*/}
                  {/*  type='date'*/}
                  {/*  component={DateSelectField}*/}
                  {/*  label="Planting Date"*/}
                  {/*  name="plantingdate"*/}
                  {/*  required*/}
                  {/*/>*/}

                  {/*<Field*/}
                  {/*  type='date'*/}
                  {/*  component={DateSelectField}*/}
                  {/*  label="Est. Harvest Date"*/}
                  {/*  name="estharvestdate"*/}
                  {/*  required*/}
                  {/*/>*/}

                  {submitError && <div className="error">{submitError}</div>}

                  <div className="mt-5 d-flex justify-content-end">
                    <button
                      className="btn btn-lg btn-primary"
                      onClick={() => navigate('/growing-seasons')}
                      type="button"
                      disabled={submitting}
                    >
                      {t('Cancel')}
                    </button>

                    <button
                      className="btn btn-lg btn-primary"
                      type="submit"
                      disabled={submitting || pristine || !values.farmland}
                    >
                      {isCreate ? t('Submit') : t('Save Changes')}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          );
        }}
    />
  );
};

export default GrowingSeasonForm;
