import React, { useEffect, useState } from  'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import qs from 'query-string';
import moment from 'moment';
import pluralize from 'pluralize';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';

import {
  ChatBubbleBottomCenterTextIcon,
  CameraIcon,
} from '@heroicons/react/24/solid'
import { useTranslation } from 'react-i18next';
import toastr from 'toastr';
import { Modal, Button } from 'react-bootstrap';

import OperationsTimeLine from './OperationsTimeLine';
import { updateOperationDS, createOperationDS } from '../dataServices/operations';
import OperationTransactionForm from './../components/forms/OperationTransactionForm';
import { deleteOperationDS } from '../dataServices/operations';
import { formatDate } from '../utils/utils';
import { fetchOperationsDS } from '../dataServices/operations';
import Pagination from './../components/Pagination';
import timeLineIcon from './../img/timeline.svg';


const OperationsList = ({ growingSeasonId, growingSeason,  farmlandId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  let [searchParams, setSearchParams] = useSearchParams();
  const operations = useSelector((state) => state?.app?.operations);
  const pagination = useSelector((state) => state?.app?.pagination);
  const { search } = useLocation();
  const currentPage = qs.parse(search).page || 1;
  const searchAsObject = Object.fromEntries(
    new URLSearchParams(searchParams)
  );
  let [showTimeLine, setShowTimeLine] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [items, setItems] = useState([]);
  const [operation, setOperation] = useState(null);
  const [showOperationModal, setShowOperationModal] = useState(false);
  const [allOperations, setAllOperations] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedType, setSelectedType] = useState('operations');

  useEffect(() => {
    fetchAllItems();
  }, [search]);

  const fetchAllItems = async () => {
    const filters = { season_id: growingSeasonId };
    dispatch(fetchOperationsDS(currentPage, 20, filters)).then(() => {
      setIsLoading(false);
    });
  };

  const onOperationSubmit = async (values) => {
    if (values.operation_id) {
      return dispatch(updateOperationDS(values))
        .then(async data => {
          if (data.success) {
            // navigate(`/operations/`);
            setShowOperationModal(false);
            setSelectedDate(new Date(values.operationdate));
            fetchAllItems();
            return;
          } else {
            if (data?.detail) {
              toastr.error(data?.detail);
            }
          }
          return data;
        })
        .catch(err => {
          console.log('ERROR #', err);
        });
    } else {
      return dispatch(createOperationDS(values))
        .then(async data => {
          if (data.success) {
            // navigate(`/operations/`);
            setShowOperationModal(false);
            await fetchAllItems();
            setSelectedDate(new Date(values.operationdate));
            return;
          } else {
            if (data?.detail) {
              toastr.error(data?.detail);
            }
          }
          return data;
        })
        .catch(err => {
          console.log('ERROR #', err);
        });
    }
  };

  const sowingDateDiff = (dt) => {
    if (!growingSeason?.sowing_date) {
      return '-';
    }

    let diff = moment(dt).diff(moment(growingSeason?.sowing_date), 'days');

    if (diff < 0) {
      // diff = diff * -1;
    }
    const unit = ( Math.abs(diff) === 1) ? t('day_abr') : t('days_abr');
    return `${diff} ${unit}`;
  };

  return (
    <>
      <OperationsTimeLine
        growingSeasonId={growingSeasonId}
        farmlandId={farmlandId}
        modalOpen={showTimeLine}
        onClose={() => setShowTimeLine(false)}
        onCreateNew={() => {
          setOperation(null);
          setShowOperationModal(true);
          setShowTimeLine(false);
        }}
      />


      <div className="w-100 d-flex mb-4 align-items-center">
        <h2 className="mb-0 me-3">{t('Operations calendar')}</h2>
        {/*<a className="link cursor-pointer" onClick={() => setShowTimeLine(true)}>*/}
        {/*  <img className="me-2" width="24" src={timeLineIcon} />*/}
        {/*  {t('Show Timeline')}*/}
        {/*</a>*/}

        <button
          disabled={!growingSeason?.isactive}
          className="btn btn-primary ms-auto"
          onClick={() => {
            setOperation(null);
            setShowOperationModal(true);
          }}
          // onClick={() => navigate(`/${selectedType}/add`)}
        >
          {t('Add Operation')}
        </button>
      </div>

      <Modal
        show={showOperationModal && !showDeleteModal}
        size="lg"
        onHide={() => setShowOperationModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            { operation ? t('Update operation') : t('Create operation') }
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <OperationTransactionForm
            farmlandId={farmlandId}
            isCreate={!operation}
            showSeason={false}
            initialValues={{
              type: 'operation',
              season: growingSeasonId,
              ...operation,
              operationfiles: operation?.operationfiles.map(f => f.fileresource),
            }}
            afterDeletion={() => {
              setShowOperationModal(false);
              fetchAllItems();
            }}
            // handleShowDeleteModal={() => {
            //   setShowDeleteModal(true);
            // }}
            onSubmit={(values) => onOperationSubmit(values)}
          />
        </Modal.Body>
      </Modal>

      <section id="operations-list">
        {isLoading && 'Loading...'}

        {!isLoading && (
          <div className="row">
            <div className="col-12">
              {!isLoading && operations && operations.length === 0 && (
                <div className="text-center p-5">
                  <i>{t('no items found')}</i>
                </div>
              )}

              {operations?.length > 0 && (
                <table className="table table--clickable">
                  <thead>
                  <tr>
                    <th scope="col">{t('Date')}</th>
                    <th scope="col">{t('DFS')}</th>
                    <th scope="col">{t('Operation')}</th>
                    <th colSpan={2} scope="col">{t('Consumption')}</th>
                  </tr>
                  </thead>

                  <tbody>
                  {operations?.length > 0 && operations.map(op => (
                    <tr
                      key={op.operation_id}
                      // onClick={() => navigate(`/operations/${op.operation_id}/edit`)}
                      onClick={() => {
                        setOperation(op);
                        setShowOperationModal(true);
                      }}
                    >
                      <td style={{ width: '120px' }} className="discreet">
                        {formatDate(op.operationdate)}
                      </td>
                      <td style={{ width: '120px' }} className="discreet">
                        {sowingDateDiff(op.operationdate)}
                      </td>
                      <td>{op.title}</td>
                      <td>{op?.consumption} lt</td>
                      <td>
                        {op?.description && (
                          <ChatBubbleBottomCenterTextIcon height={17}  className="me-3" />
                        )}
                        {op?.has_media && (
                          <CameraIcon height={17} className="me-3" />
                        )}
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              )}
            </div>

            <div className="my-4">
              <div className="w-100">
                {pagination && (
                  <Pagination
                    {...pagination}
                    changePage={newPage => {
                      setSearchParams({
                        ...searchAsObject,
                        page: newPage,
                      });
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  )
};

export default OperationsList;
