import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
// import { Marker, StaticGoogleMap } from 'react-static-google-map';
// import { getCenter } from 'geolib';
import axiosClient  from '../utils/axios';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';

import { formatDate } from '../utils/utils';
import {
  EllipsisHorizontalCircleIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline'
import { Card, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';

const GrowingSeasonListItem = ({ growingSeason, setDeleteModalPayload, setShowDeleteModal }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let soilTypes = [];
  useSelector((state) => state?.globals?.soilTypes)
    ?.forEach(st => {
      soilTypes[st.soiltype_id] = st.name;
    });

  let irrigationTypes = [];
  useSelector((state) => state?.globals?.irrigationTypes)
    ?.forEach(st => {
      irrigationTypes[st.irrigationtype_id] = st.name;
    });

  let cultivationMethods = [];
  useSelector((state) => state?.globals?.cultivationMethods)
    ?.forEach(st => {
      cultivationMethods[st.cultivationmethod_id] = st.name;
    });

  let cropTypes = [];
  useSelector((state) => state?.globals?.cropTypes)
    ?.forEach(st => {
      cropTypes[st.croptype_id] = st.name;
    });

  const fl = growingSeason.farmland;

  if(!growingSeason) {
    return;
  }

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));

  // const center = getCenter(
  //   fl?.coordinates?.coordinates?.[0]?.map(coords => (
  //     { latitude: coords[1], longitude: coords[0] }
  //   )));

  const paths = fl?.coordinates && fl?.coordinates.coordinates.map(ca => ca.map(cb => `${cb[1]},${cb[0]}`).join('|'))[0] || '';
  const imgURL = `https://maps.googleapis.com/maps/api/staticmap?size=600x600&maptype=satellite&sensor=false&path=color%3a0x0000cc|weight:3|fillcolor%3a0x35ba4f|${paths}&key=AIzaSyBYCV6nakW4zHapbRNzEUiCEMoaW10KZJ4`;

  const downloadReport = async (gsid) => {
    axiosClient.get('operations/operations_report/?season_id=' + gsid)
      .then(({ data: { file_url }}) =>{
        var win = window.open(file_url, "_blank");
    })
  };

  const downloadOperationsListExport = async (gsid) => {
    axiosClient.get('operations/operations_export/?season_id=' + gsid)
        .then(({ data: { file_url }}) =>{
          var win = window.open(file_url, "_blank");
        })
  };

  return (
    <Card
      key={growingSeason.season_id}
      className="growing-season-list-item"
      as='li'
    >
      <Card.Body className="p-0 d-flex flex-column flex-xl-row">
        <div className="growing-season-list-item__map-container">
          <Link
            className="w-100"
            to={`/growing-seasons/${growingSeason.season_id}`}
            style={{ backgroundImage: `url(${imgURL})`}}
          />
        </div>

        <div className="d-flex flex-column justify-content-center py-3 ps-4 flex-grow-1">
          <div className="d-flex align-items-center ">
            <div className="w-75 me-5 pe-5">
              <h3 className={!growingSeason?.latest_operation ? 'mb-5' : ''}>
                <Link to={`/growing-seasons/${growingSeason.season_id}`} className={growingSeason?.status === 1 ? '' : 'text-muted'}>
                  {growingSeason.name}
                </Link>

                {growingSeason?.status === 2 && (
                  <span className="text-warning ms-2 small ">{t('Completed')}</span>
                )}
              </h3>

              {growingSeason?.latest_operation && (
                <div className="mb-5 text-primary">
                  {t('Last operation')}:

                  <span className="value">
                    {' '}
                    {growingSeason?.latest_operation?.title}
                    {` (${formatDate(growingSeason?.latest_operation?.operationdate)})`}
                    </span>
                </div>
              )}
              <div className="row">
                <div className="growing-season-list-item__info-item col-6 col-lg-3 mb-3">
                  <span className="label">{t('Farmland')}</span>

                  <span className="value">
                    {growingSeason.farmland.name || '-'}
                  </span>
                </div>

                <div className="growing-season-list-item__info-item col-6 col-lg-3 mb-3">
                  <span className="label">{t('Crop')}</span>

                  <span className="value">
                    {cropTypes[growingSeason.croptype]}
                  </span>
                </div>

                <div className="growing-season-list-item__info-item col-6 col-lg-3 mb-3">
                  <span className="label">{t('Cultivation Method')}</span>

                  <span className="value">
                    {cultivationMethods[growingSeason.cultivationmethod]}
                  </span>
                </div>

                <div className="growing-season-list-item__info-item col-6 col-lg-3 mb-3">
                  <span className="label">{t('Area')}</span>
                  <span className="value">{fl?.area} ha</span>
                </div>

                {/*<div className="growing-season-list-item__info-item col-6 col-lg-3 mb-3">*/}
                {/*  <span className="label">From Date</span>*/}

                {/*  <span className="value">*/}
                {/*    {formatDate(growingSeason.fromdate)}*/}
                {/*  </span>*/}
                {/*</div>*/}

                {/*<div className="growing-season-list-item__info-item col-6 col-lg-3 mb-3">*/}
                {/*  <span className="label">To Date</span>*/}

                {/*  <span className="value">*/}
                {/*    {formatDate(growingSeason.todate)}*/}
                {/*  </span>*/}
                {/*</div>*/}
              </div>
            </div>

            <div className="ms-auto me-3 pe-2 d-flex flex-row align-items-center">
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={(props) => (
                  <Tooltip id="button-tooltip" {...props}>
                    {t('Download PDF')}
                  </Tooltip>
                )}
              >
                <Icon
                  icon="line-md:document-report"
                  height={24}
                  color="#955d3a"
                  onClick={async () => downloadReport(growingSeason.season_id)}
                  className="cursor-pointer me-2 growing-season-list-item__indicator growing-season-list-item__indicator--active"
                />
              </OverlayTrigger>

              <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) => (
                      <Tooltip id="button-tooltip" {...props}>
                        {t('Export .xlsx')}
                      </Tooltip>
                  )}
              >
                <Icon
                    icon="line-md:document-delete"
                    height={24}
                    color="#955d3a"
                    onClick={async () => downloadOperationsListExport(growingSeason.season_id)}
                    className="cursor-pointer me-2 growing-season-list-item__indicator growing-season-list-item__indicator--active"
                />
              </OverlayTrigger>

              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={(props) => (
                  <Tooltip id="button-tooltip" {...props}>
                    {irrigationTypes?.[growingSeason?.irrigationtype]}
                  </Tooltip>
                )}
              >
                <Icon
                  icon="material-symbols:water-drop-outline"
                  height={24}
                  color="#489BE8"
                  className={`me-2 growing-season-list-item__indicator ${growingSeason.irrigationtype !== 1 && 'growing-season-list-item__indicator--active' || ''}`}
                />
              </OverlayTrigger>

              {growingSeason.comments && (
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) => (
                    <Tooltip id="button-tooltip" {...props}>
                      {growingSeason.comments}
                    </Tooltip>
                  )}
                >
                  <InformationCircleIcon
                    height={24}
                    className="me-2 growing-season-list-item__indicator growing-season-list-item__indicator--active"
                  />
                </OverlayTrigger>
              )}

              {!growingSeason.comments  && (
                <InformationCircleIcon
                  height={24}
                  className="me-2 growing-season-list-item__indicator"
                />
              )}

              <Dropdown
                className="ms-auto"
                variant="secondary"
              >
                <Dropdown.Toggle as={CustomToggle}>
                  <EllipsisHorizontalCircleIcon height={24} />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => navigate(`/growing-seasons/${growingSeason.season_id}`)}
                    eventKey="view"
                  >
                    {t('View')}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => navigate(`/growing-seasons/${growingSeason.season_id}/edit`)}
                    eventKey="edit"
                  >
                    {t('Edit')}
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="delete"
                    onClick={() => {
                      setDeleteModalPayload(growingSeason);
                      setShowDeleteModal(true);
                    }}
                  >
                    <span className="text-danger">{t('Delete')}</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default GrowingSeasonListItem;
