import React, { useEffect, useState } from  'react';
import { format } from 'date-fns'
import moment from 'moment';
import 'moment/locale/el';
import toastr from 'toastr';

import SoilMoistureTriangleComponent from '../components/SoilTypeTriangle';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axiosClient from '../utils/axios';
import {
  fetchFarmLandDS,
//  fetchFarmLandGrowingSeasonsDS,
  fetchPMFilterDates,
  fetchPixelMapData,
  resetPixelMapData,
} from '../dataServices/farmLands';
import {
  PIXEL_MAP_DATA_FETCHED,
} from "../actionTypes/actionTypes";
import { fetchGrowingSeasonDS } from '../dataServices/growingSeasons';
import { Card } from 'react-bootstrap';
import {
  CheckCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid'

import { formatDate } from '../utils/utils';
import FLMap from '../components/FLMap';
import WeatherWidget from '../components/WeatherWidget';
import WeatherChart from '../components/WheatherChart';
import SensorDataChart from '../components/SensorDataChart';
import PixelMapFiltersForm from '../components/forms/PixelMapFiltersForm';
import ContentHeader from './../layouts/components/ContentHeader';
import OperationsList from './../components/OperationsList';
import "chartjs-adapter-moment";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Chart } from "react-chartjs-2";
import evapotranspirationIcon from './../img/evapotranspiration.svg';
import irrigationIcon from './../img/irrigation-icon.png';
import rainIcon from './../img/rain-icon.png';
import soilMoistureIcon from './../img/soil-moisture-field.svg';


ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const averagesChartOptions = {
  response: true,
  borderWidth: 1,
  scales: {
    x: {
      borderWidth: 1,
      type: "time",
      time: {
        unit: "week",
        unitStepSize: 2,
        displayFormats: {
          millisecond: 'MMM DD',
          second: 'MMM DD',
          minute: 'MMM DD',
          hour: 'MMM DD',
          day: 'MMM DD YYYY',
          week: 'MMM DD YYYY',
          month: 'MMM DD',
          quarter: 'MMM DD',
          year: 'MMM DD',
        },
        ticks: {
          callback: function(value, index, values) {
            return parseFloat(value).toFixed(2);
          },
          autoSkip: true,
          maxTicksLimit: 10,
          stepSize: .2,

          borderWidth: 1,
        }
      },
      title: {
        display: false,
        text: 'Date'
      },
    }
  }
};

export const sensorChartOptions = {
  response: true,
  scales: {
    x: {
      borderWidth: 1,
      type: "time",
      time: {
        unit: "week",
        unitStepSize: 2,
        displayFormats: {
          millisecond: 'MMM DD',
          second: 'MMM DD',
          minute: 'MMM DD',
          hour: 'MMM DD',
          day: 'MMM DD YYYY',
          week: 'MMM DD YYYY',
          month: 'MMM DD',
          quarter: 'MMM DD',
          year: 'MMM DD',
        },
        ticks: {
          callback: function(value, index, values) {
            return parseFloat(value).toFixed(2);
          },
          autoSkip: true,
          maxTicksLimit: 10,
          stepSize: .2,
        }
      },
      title: {
        display: false,
        text: 'Date'
      },
    }
  }
};

const fromDate = moment().subtract(6, 'months').format('yyyy-MM-DD');
const nineDaysAgo = moment().subtract(9, 'days').format('yyyy-MM-DD');
const thiWeek = moment().subtract(6, 'days').format('yyyy-MM-DD');
const twoDaysAgo = moment().subtract(2, 'days').format('yyyy-MM-DD');
const fiveDaysAgo = moment().subtract(4, 'days').format('yyyy-MM-DD');
const tenDaysAgo = moment().subtract(9, 'days').format('yyyy-MM-DD');
const toDate = moment().format('yyyy-MM-DD');


const defaultFilters = { year: new Date().getFullYear() };
const GrowingSeasonView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pMFilters, setPMFilters] = useState(defaultFilters);
  const [showOldIrrigationData, setShowOldIrrigationData] = useState(false);
  const [irrigationData, setIrrigationData] = useState(null);
  const [sensorDataLoading, setSensorDataLoading] = useState(false);
  const [sensorDataRange, setSensorDataRange] = useState(twoDaysAgo);
  const [isLoading, setIsLoading] = useState(true);
  const [averageData, setAverageData] = useState(null);
  const [sensorData, setSensorData] = useState(null);
  const [weatherData, setWeatherData] = useState(null);
  const [weatherForecast, setWeatherForecast] = useState(null);
  const [historicalWeather, setHistoricalWeather] = useState(null);
  const [isLoadingPMData, setIsLoadingPMData] = useState(false);
  const [showForecast, setShowForecast] = useState(false);
  const { growingSeasonId } = useParams();
  const growingSeason = useSelector((state) => state?.app?.growingSeason?.[growingSeasonId]);
  const farmlandId = growingSeason?.farmland;
  const farmland = useSelector((state) => state?.app?.farmLand?.[farmlandId]);
  const farmlandGrowingSeasons = useSelector((state) => state?.app?.farmLandGrowingSeasons?.[farmlandId]);
  const pixelMapData = useSelector((state) => state?.app?.pixelMapData);
  const pixelMapTypes = useSelector((state) => state?.globals?.pixelMapTypes);
  const userData = useSelector((state) => state?.auth?.user);
  const languages = useSelector((state) => state?.globals?.languages);
  let cropTypes = [];
  let colorMap;

  const keylog = [];
  useSelector((state) => state?.globals?.cropTypes)
    ?.forEach(st => {
      cropTypes[st.croptype_id] = st.name;
    });
  const { pixelMapType } = pMFilters;
  const userLangIso = languages && languages.find(l => l.language_id === userData.language)?.iso_code || 'en' ;

  moment.locale(userLangIso);

  if (pixelMapType) {
    const pmt = pixelMapTypes.find(pmt => parseInt(pmt.pmtype_id) === parseInt(pixelMapType));
    colorMap = pmt?.pmcolors?.PMcolors;
  }

  useEffect(() => {
    dispatch(fetchGrowingSeasonDS(growingSeasonId));
  }, [growingSeasonId]);

  useEffect(() => {
    function handleKeyUp(e) {
      let keypressed = e.key;
      keylog.push(keypressed);
      console.log(keylog.join(''));
      if (keylog.join('').endsWith('debug')) {
        setShowOldIrrigationData(true);
      }
    }

    window.addEventListener("keyup", handleKeyUp);
    return () => window.removeEventListener("keyup", handleKeyUp);
  }, []);


  useEffect(() => {
    if (!farmlandId) {
      return;
    }

    const fetchData = async () => {
      await Promise.all([
        dispatch(fetchFarmLandDS(farmlandId)),
        // dispatch(fetchFarmLandGrowingSeasonsDS(farmlandId)),
        dispatch(resetPixelMapData()),
      ]);


      await axiosClient.get(
        `fl/${farmlandId}/calc/hourly_weather_forecast/`
        //`fl/${farmlandId}/calc/weather_forecast/`
      )
        .then(
          ({ data }) => { setWeatherData(data); }
        );

      await axiosClient.get(
        `fl/${farmlandId}/calc/hourly_weather_forecast/`
        //`fl/${farmlandId}/calc/weather_forecast/`
      )
        .then(
          ({ data }) => { setWeatherData(data); }
        );

      await axiosClient.get(
        `se/${growingSeasonId}/irrigation/`
      )
        .then(
          ({ data }) => { setIrrigationData(data); }
        );

      await axiosClient.get(
        `fl/${farmlandId}/calc/historical_weather/?from_date=${nineDaysAgo}&to_date=${twoDaysAgo}`
      )
        .then(
          ({ data, data: { historical_weather }} ) => setHistoricalWeather(historical_weather)
        );

      if (growingSeason?.has_sensor) {
        await axiosClient.get(
          `se/${growingSeasonId}/sensor-data/?from=${twoDaysAgo}&to=${toDate}`
        )
          .then(
            ({ data }) => {
              setSensorData(data);
            }
          );
      }


      await axiosClient.get(
        `fl/${farmlandId}/calc/weather_forecast/`
      )
        .then(
          ({ data, data: { weather_forecast }} ) => setWeatherForecast(weather_forecast)
        );
      const averageData = await Promise.all([
        axiosClient
          .get(`fl/${farmlandId}/pm/average/?pmtype=1&from_date=${fromDate}&to_date=${toDate}`).then(
          ({ data }) => ({ pmt: 1, data })
        ),
        axiosClient
          .get(`fl/${farmlandId}/pm/average/?pmtype=2&from_date=${fromDate}&to_date=${toDate}`).then(
          ({ data }) => ({ pmt: 2, data })
        ),
        axiosClient
          .get(`fl/${farmlandId}/pm/average/?pmtype=3&from_date=${fromDate}&to_date=${toDate}`).then(
          ({ data }) => ({ pmt: 3, data })
        ),
        axiosClient
          .get(`fl/${farmlandId}/pm/average/?pmtype=4&from_date=${fromDate}&to_date=${toDate}`).then(
          ({ data }) => ({ pmt: 4, data })
        ),
        axiosClient
          .get(`fl/${farmlandId}/pm/average/?pmtype=6&from_date=${fromDate}&to_date=${toDate}`).then(
          ({ data }) => ({ pmt: 6, data })
        ),
      ]);
      setAverageData(averageData);
    };

    fetchFilterDates();
    fetchData();
    setIsLoading(false);
  }, [farmlandId]);

  const markAsCompleted = async () => {
    await axiosClient.patch(
      `se/${growingSeasonId}/status/`,
      { status: 2 },
    )
      .then(
        ({ data }) => {
          toastr.success('Growing season was marked as completed successfully!');
          dispatch(fetchGrowingSeasonDS(growingSeasonId));
        }
      );
  };

  const markAsActive = async () => {
    await axiosClient.patch(
      `se/${growingSeasonId}/status/`,
      { status: 1 },
    )
      .then(
        ({ data }) => {
          toastr.success('Growing season was marked as active successfully!');
          dispatch(fetchGrowingSeasonDS(growingSeasonId));
        }
      );
  };

  const fetchFilterDates = async (year = pMFilters.year) => {
    return dispatch(fetchPMFilterDates(farmlandId, year));
  };

  const onPMFiltersChange = filters => {
    setPMFilters(filters);
    setIsLoadingPMData(true);

    if (!filters.snapshotDateTime) {
      dispatch({
        type: PIXEL_MAP_DATA_FETCHED,
        payload: null,
      });
      setIsLoadingPMData(false);
      return;
    }

    dispatch(fetchPixelMapData({
      farmLandId: farmlandId,
      pmType: filters.pixelMapType,
      fromDate: format(new Date(filters.snapshotDateTime),'yyyy-MM-dd'),
      toDate: format(new Date(filters.snapshotDateTime),'yyyy-MM-dd'),
    }))
      .then(() => setIsLoadingPMData(false));
  };

  let soilTypes = [];
  useSelector((state) => state?.globals?.soilTypes)
    ?.forEach(st => {
      soilTypes[st.soiltype_id] = st.name;
    });

  let irrigationTypes = [];
  useSelector((state) => state?.globals?.irrigationTypes)
    ?.forEach(st => {
      irrigationTypes[st.irrigationtype_id] = st.name;
    });

  let cultivationMethods = [];
  useSelector((state) => state?.globals?.cultivationMethods)
    ?.forEach(st => {
      cultivationMethods[st.cultivationmethod_id] = st.name;
    });



  const values = averageData?.map(avg =>
    avg?.data?.map(({ average, pmdate }) => ({
      x: pmdate,
      y: average,
      pmt: avg.pmt,
    })));

  const data = {
    datasets: values?.map((ds, i) =>
      ({
        data: ds?.sort(function(a,b) { return new Date(b.x) - new Date(a.x)}),
        label: pixelMapTypes.find(pmt => ds?.[0]?.pmt === pmt?.pmtype_id)?.name,
      })),
  };

  const fetchSensorData = async (e, from) => {
    e.preventDefault();
    setSensorDataRange(from);
    setSensorDataLoading(true);
    await axiosClient.get(
      `se/${growingSeasonId}/sensor-data/?from=${from}&to=${toDate}`
    )
      .then(
        ({ data }) => {
          setSensorData(data);
          setSensorDataLoading(false);
        }
      );
  };


  const Indication = ({ SoilMoisture }) => {
    let color = '#05b30b';
    let icon = <CheckCircleIcon width={25} color={color} />;
    if (
      SoilMoisture >= 0.17
      && SoilMoisture < 0.2
    ) {
      color = '#ed7b09';
      icon = <CheckCircleIcon width={25} color={color} />;
    }
    if (
      SoilMoisture < 0.17
    ) {
      color = '#f01616';
      icon = <XCircleIcon width={25} color={color} />;
    }

    return (
      <div className="">
        {icon}
      </div>
    )
  };

  const IndicationNew = ({ intem: { IrrDay } }) => {
    let color;
    let icon = <CheckCircleIcon width={25} color={color} />;
    if (
        IrrDay >= 0.17
        && IrrDay < 0.2
    ) {
      color = '#ed7b09';
      icon = <CheckCircleIcon width={25} color={color} />;
    }
    if (
        IrrDay < 0.17
    ) {
      color = '#05b30b';
      icon = <XCircleIcon width={25} color={color} />;
    } else {
      color = '#f01616';
    }

    return (
        <div className="" style={{ color }}>
          {IrrDay} mm
        </div>
    )
  };

  //
  // const temperatureValues = weatherData?.weather_forecast?.map(({ date, temperature_2m_max }) => ({
  //     x: date,
  //     y: temperature_2m_max,
  //   }));
  //
  // const temperatureChartData = {
  //   datasets: [
  //     {
  //       data: temperatureValues?.sort(function(a,b) { return new Date(b.x) - new Date(a.x)}),
  //       label: 'Temperature (C)',
  //     }
  //   ]
  // };
  //
  // const precipitationValues = weatherData?.weather_forecast?.map(({ date, precipitation_sum }) => ({
  //     x: date,
  //     y: precipitation_sum,
  //   }));
  //
  // const precipitationChartData = {
  //   datasets: [
  //     {
  //       data: precipitationValues?.sort(function(a,b) { return new Date(b.x) - new Date(a.x)}),
  //       label: 'Precipitation (mm)',
  //     }
  //   ]
  // };

  const dataSet = showForecast
    ? irrigationData?.weather_data.slice(Math.max(irrigationData?.weather_data.length - 8, 0))
    : irrigationData?.weather_data?.slice(0,8);

  const weatherForecastOptions = {
    response: true,
    plugins: {
      legend: {
        display: false
      },
      tooltips: {
        callbacks: {
          label: function(tooltipItem) {
            return tooltipItem.yLabel;
          }
        }
      }
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: 'day',
          displayFormats: {
            day: 'D MMM Y',
          },
        },
        scaleLabel: {
          display: true,
          labelString: 'probability'
        },
      },
    }
  };
  const currentdate = moment().format('dddd, LL');

  return (
    <>
      <ContentHeader
        title={(
          <div>
            {growingSeason?.name}
            {/* - <span className="text-muted">{growingSeason?.cropperiod_name}</span> */}
          </div>
        )}
        cta={(
          <Dropdown as={ButtonGroup}>
            <Button
              className="text-dark"
              disabled={growingSeason?.status !== 1}
              variant="outline-primary"
              onClick={() => navigate(`/growing-seasons/${growingSeasonId}/edit`)}
            >
              {t('Edit')}
            </Button>
            <Dropdown.Toggle
              split
              variant="primary"
              className="px-3"
              id="dropdown-split-basic"
            />

            <Dropdown.Menu>
              { growingSeason?.status === 1 && (
                <Dropdown.Item
                  className="text-primary"
                  onClick={markAsCompleted}
                >
                  {t('Mark as completed')}
                </Dropdown.Item>
              )}
              { growingSeason?.status === 2 && (
                <Dropdown.Item
                  className="text-primary"
                  onClick={markAsActive}
                >
                  {t('Mark as active')}
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        )}
      />

      <section className="page page--farmland-view">
        {isLoading && (
          <div
            className="col-12 text-center"
          >
            Loading...
          </div>
        )}

        {!isLoading && farmland && (
          <>
          <div className="position-relative p-0">
            {isLoadingPMData && (
              <div className="pm-loading-indicator">
                <div>
                  Loading data...
                </div>
              </div>
            )}

            <PixelMapFiltersForm
              onYearChange={(year) => {
                setPMFilters({
                  ...pMFilters,
                  year,
                });
                fetchFilterDates(year);
              }}
              initialValues={pMFilters}
              onSubmit={onPMFiltersChange}
            />

            <FLMap
              farmLand={farmland?.coordinates}
              pixelMapData={pixelMapData}
              pMFilters={pMFilters}
              colorMap={colorMap}
              sensors={growingSeason?.sensors_location || []}
              sensorData={sensorData}
            />
          </div>

          {/*<div className="d-flex mt-4">*/}
          {/*  <div className="ms-auto px-4">*/}
          {/*    <Link*/}
          {/*      className="btn btn-primary ms-auto"*/}
          {/*      to={`/farm-lands/${farmlandId}/edit`}*/}
          {/*    >*/}
          {/*      Edit Farmland*/}
          {/*    </Link>*/}
          {/*  </div>*/}
          {/*</div>*/}

            <div className="container">
              <div className="d-flex my-4 justify-content-center align-items-center text-capitalize">
                <strong className="d-lg-none">{currentdate}</strong>
              </div>

              {growingSeason?.status === 2 && (
                <div className="col-12 border-warning border bg-light text-warning text-center p-4 mb-5">
                  {t('This growing season was marked as completed')}
                </div>
              )}
              <div className="row">


                {/*<div className="col-12 col-xl-4">*/}
                {/*  <Card>*/}
                {/*    <Card.Body>*/}
                {/*      <WeatherWidget*/}

                {/*        // locationLabel={farmland?.name}*/}
                {/*        coordinates={{*/}
                {/*          latitude: farmland?.coordinates?.coordinates?.[0][0][0],*/}
                {/*          longitude: farmland?.coordinates?.coordinates?.[0][0][1],*/}
                {/*        }}*/}
                {/*      />*/}
                {/*    </Card.Body>*/}
                {/*  </Card>*/}
                {/*</div>*/}

                <div className="col-12 col-md-4">
                  <Card>
                    <Card.Body className="pb-0">
                      <SoilMoistureTriangleComponent
                          lang={userLangIso}
                          sand={irrigationData?.sand_percentage}
                          clay={irrigationData?.clay_percentage}
                          soilType={soilTypes?.[farmland?.soiltype]}
                      />
                    </Card.Body>
                  </Card>
                </div>

                <div className="col-12 col-md-8 mb-4 mb-lg-0">
                  <section>
                    <Card className="">
                      <Card.Body className="pb-0">
                        <div className="spec-list row">
                          <div className="spec-list__item col-6 col-lg-3 col-xl-2 mb-3">
                            <span className="label">{t('Crop')}</span>
                            <span className="value">{cropTypes[growingSeason.croptype]}</span>
                          </div>

                          <div className="spec-list__item col-6 col-lg-3 col-xl-2 mb-3">
                            <span className="label">{t('Farmland')}</span>
                            <span className="value">{farmland?.name}</span>
                          </div>

                          <div className="spec-list__item col-6 col-lg-3 col-xl-2 mb-3">
                            <span className="label">{t('Cultivation Method')}</span>
                            <span className="value">{cultivationMethods?.[growingSeason?.cultivationmethod]}</span>
                          </div>

                          <div className="spec-list__item col-6 col-lg-3 col-xl-2 mb-3">
                            <span className="label">{t('Irrigation')}</span>
                            <span className="value">{irrigationTypes?.[growingSeason?.irrigationtype]}</span>
                          </div>

                          <div className="spec-list__item col-6 col-lg-3 col-xl-2 mb-3">
                            <span className="label">{t('Perimeter')}</span>
                            <span className="value">{farmland?.perimeter} m</span>
                          </div>

                          <div className="spec-list__item col-6 col-lg-3 col-xl-2 mb-3">
                            <span className="label">{t('Area')}</span>
                            <span className="value">{farmland?.area} ha</span>
                          </div>


                          {/*<div className="spec-list__item col-6 col-lg-3 col-xl-2 mb-3">*/}
                          {/*  <span className="label">{t('Soil type')}</span>*/}
                          {/*  <span className="value">{soilTypes?.[farmland?.soiltype]}</span>*/}
                          {/*</div>*/}

                          <div className="spec-list__item col-xl-4 mb-3">
                            <span className="label">{t('Comments')}</span>
                            <span className="value">{growingSeason?.comments}</span>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>

                    {/*{irrigationData?.yield_results?.[0] && (*/}
                        <div className="row">
                          <div className="col-12">
                            <h2 className="mt-4 mb-2">{t('Prediction')}</h2>
                            <Card bg="secondary">
                              <Card.Body className="pb-0">
                                <div className="row">

                                  <div className="spec-list__item col-12 col-md-6 col-xl-4 mb-3">
                                    <span className="label">{t('Harvest (days from sowing)')}</span>
                                    <span className="value">
                                    {irrigationData?.yield_results?.[0]?.['Harvest Date (Step)'] ? (irrigationData?.yield_results?.[0]?.['Harvest Date (Step)'] + ' days') : '-'}
                                  </span>
                                  </div>

                                  <div className="spec-list__item col-12 col-md-6 col-xl-4 mb-3">
                                    <span className="label">{t('Harvest Date')}</span>
                                    <span className="value">
                                    {irrigationData?.yield_results?.[0]?.['Harvest Date (YYYY/MM/DD)'] ? formatDate(irrigationData?.yield_results?.[0]?.['Harvest Date (YYYY/MM/DD)']) : '-'}
                                  </span>
                                  </div>
                                </div>
                                <div className="row">

                                  <div className="spec-list__item col-12 col-md-6 col-xl-4 mb-3">
                                    <span className="label">{t('Yield Prediction')}</span>
                                    <span className="value">
                                    {irrigationData?.yield_results?.[0]?.['Yield potential (tonne/ha)'] ? (irrigationData?.yield_results?.[0]?.['Yield potential (tonne/ha)'] + ' tonne/ha') : '-'}
                                  </span>
                                  </div>
                                  {/*<div className="spec-list__item col-12 col-md-6 col-xl-4 mb-3">*/}
                                  {/*  <span className="label">{t('Dry yield')}</span>*/}
                                  {/*  <span className="value">*/}
                                  {/*  {irrigationData?.yield_results?.[0]?.['Dry yield (tonne/ha)'] ? (irrigationData?.yield_results?.[0]?.['Dry yield (tonne/ha)'] + ' tonne/ha') : '-'}*/}
                                  {/*</span>*/}
                                  {/*</div>*/}

                                  {/*<div className="spec-list__item col-12 col-md-6 col-xl-4 mb-3">*/}
                                  {/*  <span className="label">{t('Fresh yield')}</span>*/}
                                  {/*  <span className="value">*/}
                                  {/*  {irrigationData?.yield_results?.[0]?.['Fresh yield (tonne/ha)'] ? (irrigationData?.yield_results?.[0]?.['Fresh yield (tonne/ha)'] + ' tonne/ha') : '-'}*/}
                                  {/*</span>*/}
                                  {/*</div>*/}

                                  <div className="spec-list__item col-12 col-md-6 col-xl-4 mb-3">
                                    <span className="label">{t('Seasonal irrigation')}</span>
                                    <span className="value">
                                    {irrigationData?.yield_results?.[0]?.['Seasonal irrigation (mm)'] ? (irrigationData?.yield_results?.[0]?.['Seasonal irrigation (mm)'] + ' mm') : '-'}
                                  </span>
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                          </div>
                        </div>
                    {/*)}*/}
                  </section>
                </div>
              </div>

              <div className="mt-5">
                <OperationsList
                    growingSeasonId={growingSeasonId}
                    farmlandId={farmlandId}
                    growingSeason={growingSeason}
                />
              </div>

              <div className="row">
                {/*<div className="col-12 col-lg-6">*/}
                {/*  <section className="mt-5">*/}
                {/*    <h2 className="mb-4">{t('Weather forecast')}</h2>*/}
                {/*    <Card>*/}
                {/*      <Card.Body>*/}
                {/*        { weatherData && weatherData.length === 0 && (*/}
                {/*          'Not enough data available'*/}
                {/*        )}*/}
                {/*        { weatherData && (*/}
                {/*          <Line*/}
                {/*            options={weatherForecastOptions}*/}
                {/*            data={temperatureChartData}*/}
                {/*          />*/}
                {/*        )}*/}

                {/*      </Card.Body>*/}
                {/*    </Card>*/}
                {/*  </section>*/}
                {/*</div>*/}

                {/*<div className="col-12 col-lg-6">*/}
                {/*  <section className="mt-5">*/}
              {/*    <h2 className="mb-4">{t('Precipitation forecast')}</h2>*/}
              {/*    <Card>*/}
              {/*      <Card.Body>*/}
              {/*        { precipitationChartData && precipitationChartData.length === 0 && (*/}
              {/*          'Not enough data available'*/}
              {/*        )}*/}
              {/*        { weatherData && (*/}
              {/*          <Line*/}
              {/*            options={weatherForecastOptions}*/}
              {/*            data={precipitationChartData}*/}
              {/*          />*/}
              {/*        )}*/}

              {/*      </Card.Body>*/}
              {/*    </Card>*/}
              {/*  </section>*/}
              {/*</div>*/}

                { irrigationData && dataSet && dataSet.length > 0 && (
                    <div className="col-12">
                      <section className="mt-5">
                        <h2 className="mb-4">{t('Irrigation')}</h2>
                        <div className="d-flex w-100 x-justify-content-end mb-2">
                          <a
                              onClick={() => setShowForecast(false)}
                              className={`weather-period-link ${showForecast ? '' : 'active'}`}
                          >
                            {formatDate(dataSet?.[0]?.Date)} - {formatDate(dataSet[dataSet.length - 1]?.Date)}
                          </a>
                          &ensp;|&ensp;
                          <a
                              onClick={() => setShowForecast(true)}
                              className={`weather-period-link ${showForecast ? 'active' : ''}`}
                          >
                            Forecast
                          </a>
                        </div>

                        <Card>
                          <Card.Body>
                            { historicalWeather && (
                                <div className="table-container">
                                  <table className="historical-weather-table table w-100 emphasize-last-day">
                                    <thead>
                                    <tr>
                                      <td>
                                        &ensp;
                                      </td>
                                      { dataSet?.map(hw => (
                                          <th key={hw.Date}>
                                            {formatDate(hw.Date)}
                                          </th>
                                      ))}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                      <td className="text-center py-3">
                                        <img className="mb-2" width="30" src={evapotranspirationIcon} />
                                        <br />
                                        Νερό που χάνει η καλλιέργεια
                                      </td>
                                      <td>{dataSet[dataSet.length - 8].ReferenceET} mm</td>
                                      <td>{dataSet[dataSet.length - 7].ReferenceET} mm</td>
                                      <td>{dataSet[dataSet.length - 6].ReferenceET} mm</td>
                                      <td>{dataSet[dataSet.length - 5].ReferenceET} mm</td>
                                      <td>{dataSet[dataSet.length - 4].ReferenceET} mm</td>
                                      <td>{dataSet[dataSet.length - 3].ReferenceET} mm</td>
                                      <td>{dataSet[dataSet.length - 2].ReferenceET} mm</td>
                                      <td>{dataSet[dataSet.length - 1].ReferenceET} mm</td>
                                    </tr>
                                    <tr>
                                      <td className="text-center py-3">
                                        <img className="mb-2" width="30" src={rainIcon} />
                                        <br />
                                        Νερό από βροχή
                                      </td>
                                      <td>{dataSet[dataSet.length - 8].Precipitation} mm</td>
                                      <td>{dataSet[dataSet.length - 7].Precipitation} mm</td>
                                      <td>{dataSet[dataSet.length - 6].Precipitation} mm</td>
                                      <td>{dataSet[dataSet.length - 5].Precipitation} mm</td>
                                      <td>{dataSet[dataSet.length - 4].Precipitation} mm</td>
                                      <td>{dataSet[dataSet.length - 3].Precipitation} mm</td>
                                      <td>{dataSet[dataSet.length - 2].Precipitation} mm</td>
                                      <td>{dataSet[dataSet.length - 1].Precipitation} mm</td>

                                    </tr>
                                    <tr>
                                      <td className="text-center py-3">
                                        <img className="mb-2" width="30" src={irrigationIcon} />
                                        <br />
                                        Νερό από άρδευση
                                      </td>
                                      <td>{dataSet[dataSet.length - 8].IrrigationMM} mm</td>
                                      <td>{dataSet[dataSet.length - 7].IrrigationMM} mm</td>
                                      <td>{dataSet[dataSet.length - 6].IrrigationMM} mm</td>
                                      <td>{dataSet[dataSet.length - 5].IrrigationMM} mm</td>
                                      <td>{dataSet[dataSet.length - 4].IrrigationMM} mm</td>
                                      <td>{dataSet[dataSet.length - 3].IrrigationMM} mm</td>
                                      <td>{dataSet[dataSet.length - 2].IrrigationMM} mm</td>
                                      <td>{dataSet[dataSet.length - 1].IrrigationMM} mm</td>
                                    </tr>
                                    <tr>
                                      <td className="text-center py-3">
                                        <img className="mb-2" width="30" src={soilMoistureIcon} />
                                        <br />
                                        Υγρασία εδάφους
                                      </td>
                                      <td>{dataSet[dataSet.length - 8].SoilMoisture} %</td>
                                      <td>{dataSet[dataSet.length - 7].SoilMoisture} %</td>
                                      <td>{dataSet[dataSet.length - 6].SoilMoisture} %</td>
                                      <td>{dataSet[dataSet.length - 5].SoilMoisture} %</td>
                                      <td>{dataSet[dataSet.length - 4].SoilMoisture} %</td>
                                      <td>{dataSet[dataSet.length - 3].SoilMoisture} %</td>
                                      <td>{dataSet[dataSet.length - 2].SoilMoisture} %</td>
                                      <td>{dataSet[dataSet.length - 1].SoilMoisture} %</td>
                                    </tr>
                                    <tr>
                                      <td className="text-center py-4">
                                        {/*<img className="mb-2" width="30" src={evapotranspirationIcon} />*/}
                                        {/*<br />*/}
                                        Ανάγκες άρδευσης
                                      </td>
                                      <td>
                                        <IndicationNew
                                            intem={dataSet[dataSet.length - 8]}
                                        />
                                      </td>
                                      <td>
                                        <IndicationNew
                                            intem={dataSet[dataSet.length - 7]}
                                        />
                                      </td>
                                      <td className="text-center py-4">
                                        <IndicationNew
                                            intem={dataSet[dataSet.length - 6]}
                                        />
                                      </td>
                                      <td className="text-center py-4">
                                        <IndicationNew
                                            intem={dataSet[dataSet.length - 5]}
                                        />
                                      </td>
                                      <td className="text-center py-4">
                                        <IndicationNew
                                            intem={dataSet[dataSet.length - 4]}
                                        />
                                      </td>
                                      <td className="text-center py-4">
                                        <IndicationNew
                                            intem={dataSet[dataSet.length - 3]}
                                        />
                                      </td>
                                      <td className="text-center py-4">
                                        <IndicationNew
                                            intem={dataSet[dataSet.length - 2]}
                                        />
                                      </td>

                                      <td className="text-center py-4">
                                        <IndicationNew
                                            intem={dataSet[dataSet.length - 1]}
                                        />
                                      </td>
                                    </tr>
                                    {/*<tr>*/}
                                    {/*  <td>Ισοζύγιο νερού</td>*/}
                                    {/*  <td>-</td>*/}
                                    {/*  <td>2</td>*/}
                                    {/*  <td>3</td>*/}
                                    {/*  <td>4</td>*/}
                                    {/*  <td>5</td>*/}
                                    {/*  <td>6</td>*/}
                                    {/*  <td>7</td>*/}
                                    {/*</tr>*/}
                                    </tbody>
                                  </table>
                                </div>
                            )}
                          </Card.Body>
                        </Card>
                      </section>
                    </div>
                )}


              {showOldIrrigationData && (
              <div className="col-12">
                <section className="mt-5">
                  <h2 className="mb-4">{t('Irrigation')} <span className="text-muted">- Old version</span></h2>

                  { historicalWeather && weatherForecast && (
                    <div className="d-flex w-100 x-justify-content-end mb-2">
                      <a
                        onClick={() => setShowForecast(false)}
                        className={`weather-period-link ${showForecast ? '' : 'active'}`}
                      >
                        {historicalWeather?.[1]?.date} - {weatherForecast[3]?.date}
                      </a>
                      &ensp;|&ensp;
                      <a
                        onClick={() => setShowForecast(true)}
                        className={`weather-period-link ${showForecast ? 'active' : ''}`}
                      >
                        {weatherForecast[3]?.date} - {weatherForecast[9]?.date}
                      </a>
                    </div>
                  )}
                  <Card>
                    <Card.Body>
                      { historicalWeather && weatherForecast && !showForecast && (
                        <div className="table-container">
                          <table className="historical-weather-table table w-100 emphasize-last-day">
                            <thead>
                            <tr>
                              <td>
                                &ensp;
                              </td>
                              { historicalWeather.slice(Math.max(historicalWeather.length - 7, 1)).map(hw => (
                                <th key={hw.date}>
                                  {hw.date}
                                </th>
                              ))}
                              <th>
                                {weatherForecast[1]?.date}
                              </th>
                              <th>
                                {weatherForecast[2]?.date}
                              </th>
                              <th>
                                {weatherForecast[3]?.date}
                              </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td className="text-center py-3">
                                <img className="mb-2" width="30" src={evapotranspirationIcon} />
                                <br />
                                Νερό που χάνει η καλλιέργεια
                              </td>
                              <td>{historicalWeather[historicalWeather.length - 7].et0_fao_evapotranspiration} mm</td>
                              <td>{historicalWeather[historicalWeather.length - 6].et0_fao_evapotranspiration} mm</td>
                              <td>{historicalWeather[historicalWeather.length - 5].et0_fao_evapotranspiration} mm</td>
                              <td>{historicalWeather[historicalWeather.length - 4].et0_fao_evapotranspiration} mm</td>
                              <td>{historicalWeather[historicalWeather.length - 3].et0_fao_evapotranspiration} mm</td>
                              <td>{historicalWeather[historicalWeather.length - 2].et0_fao_evapotranspiration} mm</td>
                              <td>{historicalWeather[historicalWeather.length - 1].et0_fao_evapotranspiration} mm</td>
                              <td>{weatherForecast[1].et0_fao_evapotranspiration} mm</td>
                              <td>{weatherForecast[2].et0_fao_evapotranspiration} mm</td>
                              <td>{weatherForecast[3].et0_fao_evapotranspiration} mm</td>
                            </tr>
                            <tr>
                              <td className="text-center py-3">
                                <img className="mb-2" width="30" src={rainIcon} />
                                <br />
                                Νερό από βροχή
                              </td>
                              <td>{historicalWeather[historicalWeather.length - 7].rain_sum} mm</td>
                              <td>{historicalWeather[historicalWeather.length - 6].rain_sum} mm</td>
                              <td>{historicalWeather[historicalWeather.length - 5].rain_sum} mm</td>
                              <td>{historicalWeather[historicalWeather.length - 4].rain_sum} mm</td>
                              <td>{historicalWeather[historicalWeather.length - 3].rain_sum} mm</td>
                              <td>{historicalWeather[historicalWeather.length - 2].rain_sum} mm</td>
                              <td>{historicalWeather[historicalWeather.length - 1].rain_sum} mm</td>
                              <td>{weatherForecast[1]?.rain_sum} mm</td>
                              <td>{weatherForecast[2]?.rain_sum} mm</td>
                              <td>{weatherForecast[3]?.rain_sum} mm</td>
                            </tr>
                            <tr>
                              <td className="text-center py-3">
                                <img className="mb-2" width="30" src={irrigationIcon} />
                                <br />
                                Νερό από άρδευση
                              </td>
                              <td>{historicalWeather[historicalWeather.length - 7].irrigation_mm} mm</td>
                              <td>{historicalWeather[historicalWeather.length - 6].irrigation_mm} mm</td>
                              <td>{historicalWeather[historicalWeather.length - 5].irrigation_mm} mm</td>
                              <td>{historicalWeather[historicalWeather.length - 4].irrigation_mm} mm</td>
                              <td>{historicalWeather[historicalWeather.length - 3].irrigation_mm} mm</td>
                              <td>{historicalWeather[historicalWeather.length - 2].irrigation_mm} mm</td>
                              <td>{historicalWeather[historicalWeather.length - 1].irrigation_mm} mm</td>
                              <td>{weatherForecast[1].irrigation_mm} mm</td>
                              <td>{weatherForecast[2].irrigation_mm} mm</td>
                              <td>{weatherForecast[3].irrigation_mm} mm</td>
                            </tr>
                            <tr>
                              <td className="text-center py-3">
                                <img className="mb-2" width="30" src={soilMoistureIcon} />
                                <br />
                                Υγρασία εδάφους
                              </td>
                              <td>{historicalWeather[historicalWeather.length - 7].daily_avg_soil_moisture_0_to_7cm} m<sup>3</sup>/m<sup>3</sup></td>
                              <td>{historicalWeather[historicalWeather.length - 6].daily_avg_soil_moisture_0_to_7cm} m<sup>3</sup>/m<sup>3</sup></td>
                              <td>{historicalWeather[historicalWeather.length - 5].daily_avg_soil_moisture_0_to_7cm} m<sup>3</sup>/m<sup>3</sup></td>
                              <td>{historicalWeather[historicalWeather.length - 4].daily_avg_soil_moisture_0_to_7cm} m<sup>3</sup>/m<sup>3</sup></td>
                              <td>{historicalWeather[historicalWeather.length - 3].daily_avg_soil_moisture_0_to_7cm} m<sup>3</sup>/m<sup>3</sup></td>
                              <td>{historicalWeather[historicalWeather.length - 2].daily_avg_soil_moisture_0_to_7cm} m<sup>3</sup>/m<sup>3</sup></td>
                              <td>{historicalWeather[historicalWeather.length - 1].daily_avg_soil_moisture_0_to_7cm} m<sup>3</sup>/m<sup>3</sup></td>
                              <td>{weatherForecast[1].daily_avg_soil_moisture_3_to_9cm} m<sup>3</sup>/m<sup>3</sup></td>
                              <td>{weatherForecast[2].daily_avg_soil_moisture_3_to_9cm} m<sup>3</sup>/m<sup>3</sup></td>
                              <td>{weatherForecast[3].daily_avg_soil_moisture_3_to_9cm} m<sup>3</sup>/m<sup>3</sup></td>
                            </tr>
                            <tr>
                              <td className="text-center py-4">
                                {/*<img className="mb-2" width="30" src={evapotranspirationIcon} />*/}
                                {/*<br />*/}
                                Ανάγκες άρδευσης
                              </td>
                              <td>
                                <Indication
                                  intem={historicalWeather[historicalWeather.length - 7]}
                                />
                              </td>
                              <td>
                                <Indication
                                  intem={historicalWeather[historicalWeather.length - 6]}
                                />
                              </td>
                              <td>
                                <Indication
                                  intem={historicalWeather[historicalWeather.length - 5]}
                                />
                              </td>
                              <td>
                                <Indication
                                  intem={historicalWeather[historicalWeather.length - 4]}
                                />
                              </td>
                              <td>
                                <Indication
                                  intem={historicalWeather[historicalWeather.length - 3]}
                                />
                              </td>
                              <td>
                                <Indication
                                  intem={historicalWeather[historicalWeather.length - 2]}
                                />
                              </td>
                              <td>
                                <Indication
                                  intem={historicalWeather[historicalWeather.length - 1]}
                                />
                              </td>
                              <td>
                                <Indication
                                  intem={weatherForecast[1]}
                                />
                              </td>
                              <td>
                                <Indication
                                  intem={weatherForecast[2]}
                                />
                              </td>
                              <td>
                                <Indication
                                  intem={weatherForecast[3]}
                                />
                              </td>
                            </tr>
                            {/*<tr>*/}
                            {/*  <td>Ισοζύγιο νερού</td>*/}
                            {/*  <td>-</td>*/}
                            {/*  <td>2</td>*/}
                            {/*  <td>3</td>*/}
                            {/*  <td>4</td>*/}
                            {/*  <td>5</td>*/}
                            {/*  <td>6</td>*/}
                            {/*  <td>7</td>*/}
                            {/*</tr>*/}
                            </tbody>
                          </table>
                        </div>
                      )}

                      { historicalWeather && weatherForecast && showForecast && (
                        <div className="table-container">
                          <table className="historical-weather-table table w-100">
                            <thead>
                            <tr>
                              <th>
                                &ensp;
                              </th>
                              <th>
                                {weatherForecast[3]?.date}
                              </th>
                              <th>
                                {weatherForecast[4]?.date}
                              </th>
                              <th>
                                {weatherForecast[5]?.date}
                              </th>
                              <th>
                                {weatherForecast[6]?.date}
                              </th>
                              <th>
                                {weatherForecast[7]?.date}
                              </th>
                              <th>
                                {weatherForecast[8]?.date}
                              </th>
                              <th>
                                {weatherForecast[9]?.date}
                              </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td className="text-center py-3">
                                <img className="mb-2" width="30" src={evapotranspirationIcon} />
                                <br />
                                Νερό που χάνει η καλλιέργεια
                              </td>
                              <td>{weatherForecast[3].et0_fao_evapotranspiration} mm</td>
                              <td>{weatherForecast[4].et0_fao_evapotranspiration} mm</td>
                              <td>{weatherForecast[5].et0_fao_evapotranspiration} mm</td>
                              <td>{weatherForecast[6].et0_fao_evapotranspiration} mm</td>
                              <td>{weatherForecast[7].et0_fao_evapotranspiration} mm</td>
                              <td>{weatherForecast[8].et0_fao_evapotranspiration} mm</td>
                              <td>{weatherForecast[9].et0_fao_evapotranspiration} mm</td>
                            </tr>
                            <tr>
                              <td className="text-center py-3">
                                <img className="mb-2" width="30" src={rainIcon} />
                                <br />
                                Νερό από βροχή
                              </td>
                              <td>{weatherForecast[3].rain_sum} mm</td>
                              <td>{weatherForecast[4].rain_sum} mm</td>
                              <td>{weatherForecast[5].rain_sum} mm</td>
                              <td>{weatherForecast[6].rain_sum} mm</td>
                              <td>{weatherForecast[7].rain_sum} mm</td>
                              <td>{weatherForecast[8].rain_sum} mm</td>
                              <td>{weatherForecast[9].rain_sum} mm</td>
                            </tr>
                            <tr>
                              <td className="text-center py-3">
                                <img className="mb-2" width="30" src={irrigationIcon} />
                                <br />
                                Νερό από άρδευση
                              </td>
                              <td>{weatherForecast[3].irrigation_mm} mm</td>
                              <td>{weatherForecast[4].irrigation_mm} mm</td>
                              <td>{weatherForecast[5].irrigation_mm} mm</td>
                              <td>{weatherForecast[6].irrigation_mm} mm</td>
                              <td>{weatherForecast[7].irrigation_mm} mm</td>
                              <td>{weatherForecast[8].irrigation_mm} mm</td>
                              <td>{weatherForecast[9].irrigation_mm} mm</td>
                            </tr>
                            <tr>
                              <td className="text-center py-3">
                                <img className="mb-2" width="30" src={soilMoistureIcon} />
                                <br />
                                Υγρασία εδάφους
                              </td>
                              <td>{weatherForecast[3].daily_avg_soil_moisture_3_to_9cm} m<sup>3</sup>/m<sup>3</sup></td>
                              <td>{weatherForecast[4].daily_avg_soil_moisture_3_to_9cm} m<sup>3</sup>/m<sup>3</sup></td>
                              <td>{weatherForecast[5].daily_avg_soil_moisture_3_to_9cm} m<sup>3</sup>/m<sup>3</sup></td>
                              <td>{weatherForecast[6].daily_avg_soil_moisture_3_to_9cm} m<sup>3</sup>/m<sup>3</sup></td>
                              <td>{weatherForecast[7].daily_avg_soil_moisture_3_to_9cm} m<sup>3</sup>/m<sup>3</sup></td>
                              <td>{weatherForecast[8].daily_avg_soil_moisture_3_to_9cm} m<sup>3</sup>/m<sup>3</sup></td>
                              <td>{weatherForecast[9].daily_avg_soil_moisture_3_to_9cm} m<sup>3</sup>/m<sup>3</sup></td>
                            </tr>
                            <tr>
                              <td className="text-center py-4">
                                {/*<img className="mb-2" width="30" src={evapotranspirationIcon} />*/}
                                {/*<br />*/}
                                Ανάγκες άρδευσης
                              </td>
                              <td>
                                <Indication
                                  intem={weatherForecast[3]}
                                />
                              </td>
                              <td>
                                <Indication
                                  intem={weatherForecast[4]}
                                />
                              </td>
                              <td>
                                <Indication
                                  intem={weatherForecast[5]}
                                />
                              </td>
                              <td>
                                <Indication
                                  intem={weatherForecast[6]}
                                />
                              </td>
                              <td>
                                <Indication
                                  intem={weatherForecast[7]}
                                />
                              </td>
                              <td>
                                <Indication
                                  intem={weatherForecast[8]}
                                />
                              </td>
                              <td>
                                <Indication
                                  intem={weatherForecast[9]}
                                />
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </section>
              </div>
              )}

              <div className="col-12">
                <section className="mt-5">
                  <h2 className="mb-4 d-none d-lg-block">&ensp;</h2>
                  {/*<h2 className="mb-4">{t('Weather forecast')}</h2>*/}
                  <Card>
                    <Card.Body>
                      { weatherData && (
                        <WeatherChart
                          weatherData={weatherData}
                        />
                      )}
                    </Card.Body>
                  </Card>
                </section>
              </div>

              <div className="col-12">
                <section className="mt-5">
                  <h2 className="mb-4">{t('Temporal Evolution of Vegetation Indices')}</h2>
                  <Card>
                    <Card.Body>
                      { averageData && averageData.length > 0 && (
                        <Line
                          options={averagesChartOptions}
                          data={data}
                        />
                      )}
                    </Card.Body>
                  </Card>
                </section>
              </div>

              { sensorData && (
                <div className="col-12">
                  <section className="mt-5">
                    <h2 className="mb-4">{t('Sensor data')}</h2>
                    <a
                      href="#"
                      className={sensorDataRange === twoDaysAgo ? 'text-primary' : 'text-muted'}
                      onClick={ (e) => fetchSensorData(e, twoDaysAgo)}
                    >
                      Last 3 days
                    </a>
                    {' | '}
                    <a
                      href="#"
                      className={sensorDataRange === thiWeek ? 'text-primary' : 'text-muted'}
                      onClick={ (e) => fetchSensorData(e, fiveDaysAgo)}
                    >
                      Last 5 days
                    </a>
                    {' | '}
                    <a
                      href="#"
                      className={sensorDataRange === thiWeek ? 'text-primary' : 'text-muted'}
                      onClick={ (e) => fetchSensorData(e, tenDaysAgo)}
                    >
                      Last 10 days
                    </a>
                    <Card className="mt-2">
                      <Card.Body className="position-relative">
                        { sensorDataLoading && (
                          <div className="loading-indicator">
                            {t('Loading...')}
                          </div>
                        )}
                        <SensorDataChart
                          sensorData={sensorData}
                        />
                      </Card.Body>
                    </Card>
                  </section>
                </div>
              )}
            </div>

            <div className="mt-4">
              <button
                className="btn btn-lg btn-primary"
                onClick={() => navigate('/growing-seasons')}
                type="button"
              >
                &larr;
                {' '}
                {t('Return to growing seasons')}
              </button>
            </div>
          </div>
          </>
        )}
      </section>
    </>
  )
};

export default GrowingSeasonView;
