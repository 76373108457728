import React, { useEffect, useState } from  'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import qs from 'query-string';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import ContentHeader from './../layouts/components/ContentHeader';
import { fetchGrowingSeasonsDS, deleteGrowingSeasonDS } from '../dataServices/growingSeasons';
import GrowingSeasonListItem from './GrowingSeasonListItem';
import SearchForm from '../components/forms/SearchForm';
import Pagination from './../components/Pagination';

const GrowingSeasonsView = ({ onMenuToggle }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalPayload, setDeleteModalPayload] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();
  const growingSeasons = useSelector((state) => state?.app?.growingSeasons);
  const pagination = useSelector((state) => state?.app?.pagination);
  const { search } = useLocation();
  const currentPage = qs.parse(search).page || 1;
  const searchAsObject = Object.fromEntries(
    new URLSearchParams(searchParams)
  );

  useEffect(() => {
    const searchParams = qs.parse(search);
    dispatch(fetchGrowingSeasonsDS(currentPage, undefined, {
      ...searchParams,
      status: searchParams?.status || 1,
    })).then(() => {
      setIsLoading(false);
      document.querySelector('.page').scrollTo({top: 0, behavior: 'smooth'});
    });
  }, [search]);

  const handleDelete = async () => {
    const res = await dispatch(deleteGrowingSeasonDS(deleteModalPayload?.season_id));
    setShowDeleteModal(false);
    setDeleteModalPayload(null);
    setIsLoading(true);
    dispatch(fetchGrowingSeasonsDS()).then(() => {
      setIsLoading(false);
    });
  }

  const onStatusChanges = async (newStatus) => {
    console.log({ newStatus });
    setSearchParams({
      ...searchAsObject,
      status: newStatus,
    });
  }

  return (
    <>
      <ContentHeader
        title={t('My Growing Seasons')}
        cta={(
          <Link
            className="btn btn-primary ms-auto"
            to="/growing-seasons/add"
          >
            {t('New Growing Season')}
          </Link>
        )}
      />

      <section className="page">
        <div className="container">
          {isLoading && t('Lading...')}

          {!isLoading && (
            <div className="row">
              <SearchForm
                searchParams={searchParams}
                onStatusChanges={onStatusChanges}
                isGrowingSeason
                searchAsObject={searchAsObject}
                initialValues={{
                  q: searchAsObject?.q,
                  from_date: searchAsObject?.from_date,
                  to_date: searchAsObject?.to_date,
                  status: searchAsObject?.status || 1,
                }}
                onSubmit={(values => {
                  setSearchParams({
                    ...searchAsObject,
                    ...values,
                    q: values.q || '',
                    from_date: values.from_date || '',
                    to_date: values.to_date || '',
                    page: 1,
                  });
                })}
              />

              {!isLoading && growingSeasons && growingSeasons.length === 0 && (
                <div className="text-center p-5">
                  <i>{t('no growing season found')}</i>
                  <br />
                  <Link to="/growing-seasons/add">
                    {t('Click here to add one now')}
                  </Link>
                </div>
              )}

              <ul className="items-list">
                {growingSeasons.map((gs) => (
                  <GrowingSeasonListItem
                    key={gs.season_id}
                    growingSeason={gs}
                    setDeleteModalPayload={setDeleteModalPayload}
                    setShowDeleteModal={setShowDeleteModal}
                  />
                ))}

                <div className="my-4">
                  {/*<div className="text-muted">Total farmlands: {pagination.count}</div>*/}

                  <div className="w-100">
                    {pagination && (
                      <Pagination
                        {...pagination}
                        changePage={newPage => {
                          setSearchParams({
                            ...searchAsObject,
                            page: newPage,
                          });
                        }}
                      />
                    )}
                  </div>
                </div>
              </ul>
            </div>
          )}
        </div>

        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{t('Delete Growing Season')}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {t('Are you sure you want to delete growing season')}
            <strong> {deleteModalPayload?.name}</strong>
            ?
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
              {t('Close')}
            </Button>

            <Button
              variant="danger"
              onClick={handleDelete}
            >
              {t('Delete')}
            </Button>
          </Modal.Footer>
        </Modal>
      </section>
    </>
  )
};

export default GrowingSeasonsView;
